import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { eq } from "truth-helpers";
import FKLabel from "discourse/form-kit/components/fk/label";
import uniqueId from "discourse/helpers/unique-id";
import withEventValue from "discourse/helpers/with-event-value";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const radioTitle = setComponentTemplate(createTemplateFactory(
/*
  
  <span class="form-kit__control-radio-title">{{yield}}</span>

*/
{
  "id": "HRWyCQma",
  "block": "[[[1,\"\\n  \"],[10,1],[14,0,\"form-kit__control-radio-title\"],[12],[18,1,null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/radio-group/radio.js",
  "isStrictMode": true
}), templateOnly());
const radioDescription = setComponentTemplate(createTemplateFactory(
/*
  
  <span class="form-kit__control-radio-description">{{yield}}</span>

*/
{
  "id": "4aCKKZli",
  "block": "[[[1,\"\\n  \"],[10,1],[14,0,\"form-kit__control-radio-description\"],[12],[18,1,null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/radio-group/radio.js",
  "isStrictMode": true
}), templateOnly());
const FKControlRadioGroupRadio = setComponentTemplate(createTemplateFactory(
/*
  
  {{#let (uniqueId) as |uuid|}}
    <div class="form-kit__field form-kit__field-radio">
      <FKLabel @fieldId={{uuid}} class="form-kit__control-radio-label">
        <input
          name={{@field.name}}
          type="radio"
          value={{@value}}
          checked={{eq @groupValue @value}}
          id={{uuid}}
          class="form-kit__control-radio"
          disabled={{@field.disabled}}
          ...attributes
          {{on "change" (withEventValue @field.set)}}
        />
        <span class="form-kit__control-radio-content">
          {{yield (hash Title=radioTitle Description=radioDescription)}}
        </span>
      </FKLabel>
    </div>
  {{/let}}

*/
{
  "id": "/IC3k3Ba",
  "block": "[[[1,\"\\n\"],[44,[[28,[32,0],null,null]],[[[1,\"    \"],[10,0],[14,0,\"form-kit__field form-kit__field-radio\"],[12],[1,\"\\n      \"],[8,[32,1],[[24,0,\"form-kit__control-radio-label\"]],[[\"@fieldId\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[11,\"input\"],[16,3,[30,2,[\"name\"]]],[24,4,\"radio\"],[16,2,[30,3]],[16,\"checked\",[28,[32,2],[[30,4],[30,3]],null]],[16,1,[30,1]],[24,0,\"form-kit__control-radio\"],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,5],[4,[32,3],[\"change\",[28,[32,4],[[30,2,[\"set\"]]],null]],null],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"form-kit__control-radio-content\"],[12],[1,\"\\n          \"],[18,6,[[28,[32,5],null,[[\"Title\",\"Description\"],[[32,6],[32,7]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]]],[\"uuid\",\"@field\",\"@value\",\"@groupValue\",\"&attrs\",\"&default\"],false,[\"let\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/radio-group/radio.js",
  "scope": () => [uniqueId, FKLabel, eq, on, withEventValue, hash, radioTitle, radioDescription],
  "isStrictMode": true
}), templateOnly());
export default FKControlRadioGroupRadio;